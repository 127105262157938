body {
  font-family: 'Handlee', cursive;
  padding:0;
  margin: 0;
  min-height:100vh;
}
.logo{
  font-family: 'Delius Swash Caps', cursive;
  font-size: 50px;
}

.header{
  height: auto;
  top:0;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
}
.repsonsive{
  height:auto;
  width: 100%;
}